<template>
  <div>
    <section class="relative bg-lime-green-50">
      <div
        class="w-full pt-8 pb-10 mx-auto text-center max-w-7xl lg:py-32 lg:text-left"
      >
        <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <span
            class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium text-cool-blue-500 mx-auto font-bold"
          >
            Roofone Float
          </span>
          <h1
            class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl"
          >
            <span class="inline text-gray-700">Ready. </span>
            <span class="inline text-gray-700">Set. </span>
            <span class="inline text-gray-700">Home.</span>
          </h1>
          <p
            class="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl"
          >
            Pay 20% upfront and focus on other things that matter.
          </p>
          <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <button
                class="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white border border-transparent rounded-md bg-lime-green-600 hover:bg-lime-green-700 md:py-4 md:text-lg md:px-10"
                @click="showPreQualificationModal"
              >
                Get Pre-qualified
              </button>
            </div>
            <!-- <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <a
                href="#"
                class="flex items-center justify-center w-full px-8 py-3 text-base font-medium bg-white border border-transparent rounded-md hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
              >
                Compare
              </a>
            </div> -->
          </div>
        </div>
      </div>

      <div
        class="relative hidden w-full h-64 lg:block sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full"
      >
        <img
          class="absolute inset-0 object-cover w-full h-full"
          src="https://images.unsplash.com/photo-1601002257790-ebe0966a85ae?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1776&q=80"
          alt="Home hero image"
        />
      </div>
    </section>

    <div class="bg-white">
      <div class="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-24 lg:px-8">
        <div class="max-w-3xl mx-auto text-center">
          <h2 class="text-3xl font-extrabold text-gray-900">
            Maximize your buying power. <br />
            Minimize your stress.
          </h2>
          <p class="mt-4 text-lg text-gray-500">
            Use our simple Float tools to help know how much you can afford.
            Pre-approval in less that 5 minutes.
          </p>
        </div>
        <dl
          class="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8"
        >
          <div class="relative">
            <dt>
              <CheckIcon
                class="absolute w-6 h-6 text-green-500"
                aria-hidden="true"
              />
              <p class="text-lg font-medium leading-6 text-gray-900 ml-9">
                Affordability Calculator
              </p>
            </dt>
            <dd class="mt-2 text-base text-gray-500 ml-9">
              When searching for a home it helps to know how much you can
              afford. Use our easy to use affordability calculator.
              <p class="italic">Coming Soon</p>
            </dd>
          </div>
          <div class="relative">
            <dt>
              <CheckIcon
                class="absolute w-6 h-6 text-green-500"
                aria-hidden="true"
              />
              <p class="text-lg font-medium leading-6 text-gray-900 ml-9">
                Float Calculator
              </p>
            </dt>
            <dd class="mt-2 text-base text-gray-500 ml-9">
              Get an estimate of the monthly payments you’ll make based on your
              loan request.
              <br />
              <br />
              <router-link
                to="/mortgage-calculator"
                class="underline text-cool-blue-600"
                >Learn more</router-link
              >
            </dd>
          </div>
          <div class="relative">
            <dt>
              <CheckIcon
                class="absolute w-6 h-6 text-green-500"
                aria-hidden="true"
              />
              <p class="text-lg font-medium leading-6 text-gray-900 ml-9">
                Buy with Float vs Renting
              </p>
            </dt>
            <dd class="mt-2 text-base text-gray-500 ml-9">
              Calculate the potential benefits of buying a home as opposed to
              renting a home.
              <br />
              <br />
              <p class="italic">Coming Soon</p>
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div class="relative bg-cool-blue-200">
      <div
        class="relative flex flex-col items-center justify-center px-4 py-12 mx-auto md:flex-row min-h-128 max-w-7xl sm:px-6 lg:px-8 lg:py-16"
      >
        <img src="@/assets/img/floatIllustration.svg" class="w-72 h-96" />
        <div class="md:w-1/2 md:pl-40">
          <h2
            class="text-base font-semibold tracking-wider text-gray-600 uppercase"
          >
            Total Transparency
          </h2>
          <p
            class="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
          >
            Aimed at putting you under a roof
          </p>
          <p class="mt-3 text-lg text-gray-600">
            We know how stressful it is to get a home, so we are focused on
            making the process simple, transparent and smart.
          </p>
        </div>
      </div>
    </div>

    <div class="py-12 bg-gray-100">
      <div class="flex flex-col items-center px-5 md:px-0">
        <h3 class="text-4xl font-bold text-gray-900 md:text-3xl">
          First-time Homeowners Need Float.
        </h3>
        <p class="mt-5">
          Find out why over 100 people are waiting to
          <span class="text-lime-green-600">Buy With Float</span>
        </p>
      </div>
      <div class="flex pb-5 mt-10 overflow-x-auto">
        <div
          class="flex flex-col justify-between flex-shrink-0 p-6 ml-10 mr-10 text-base text-white rounded-lg h-44 bg-cool-blue-500 w-96"
        >
          <div>It's easier to own a home through installment</div>
          <div>Adebanjo</div>
        </div>
        <div
          class="flex flex-col justify-between flex-shrink-0 p-6 mr-10 text-base text-white rounded-lg h-44 bg-cool-blue-500 w-128"
        >
          <div>
            I would really love to own a home,so seeing an opportunity flexible
            enough to let me pay monthly in order to own my dream home.
          </div>
          <div>Edidiong</div>
        </div>
        <div
          class="flex flex-col justify-between flex-shrink-0 p-6 mr-10 text-base text-white rounded-lg h-44 bg-cool-blue-500 w-96"
        >
          <div>
            Paying that in monthly chunks would be less of a financial burden
          </div>
          <div>Obafemi</div>
        </div>
        <div
          class="flex flex-col justify-between flex-shrink-0 p-6 mr-10 text-base text-white rounded-lg h-44 bg-cool-blue-500 w-96"
        >
          <div>Cause l need a home to call mine</div>
          <div>Folakemi</div>
        </div>
      </div>
    </div>

    <div class="bg-gray-100">
      <div class="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-20 lg:px-8">
        <div class="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 class="text-3xl font-extrabold text-gray-900">
              Frequently asked
              <span class="text-lime-green-600">Float</span> questions
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              Can’t find the answer you’re looking for? Reach out to our
              <a
                href="mailto:info@roofonecapital.com"
                class="font-medium text-lime-green-600 hover:text-lime-green-500"
                >customer support</a
              >
              team.
            </p>
          </div>
          <div class="mt-12 lg:mt-0 lg:col-span-2">
            <dl class="space-y-12">
              <div>
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  How much do you need to put down on a house?
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Just a minimum of 20% Down Payment. How much you put most
                  times depends on your goals and type of float request you
                  require. Regardless of your float request, there are clear
                  advantages for putting more money down: it can lower your
                  monthly payment, etc.
                </dd>
              </div>
              <div>
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  How much mortgage can I afford?
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  To determine how much you can afford, you can use you our
                  affordability calculator here to get an estimate. As a rule of
                  thumb, it is advised that your debt to income ratio (which is
                  all of your monthly debt payments divided by your gross
                  monthly payment) should no more that 33%.
                </dd>
              </div>
              <div>
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  What is Roofone Float pre-qualification?
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  It’s an initial assessment by us that states how much Float
                  you may qualify for. You simply provide information about your
                  income, debts and assets. It can give you a good idea of how
                  much you can afford to spend on your home, and can show
                  sellers that you’re a serious and credible buyer.
                </dd>
              </div>
              <div>
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  How do I make monthly payments?
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  You can either make a monthly transfer or enable a direct
                  debit for repayment. You can also discuss other payment
                  options with our customer service here.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <Modal
      name="preQualificationModal"
      :focusTrap="true"
      height="600px"
      :width="isMobile() ? '100%' : '50%'"
    >
      <PreQualificationForm :closeForm="hidePreQualificationModal" />
    </Modal>
  </div>
</template>

<script>
import CheckIcon from '@/components/icons/RegularCheckIcon';
import PreQualificationForm from '../pages/MortgageCalculator/PreQualificationForm.vue';

export default {
  data() {
    return {
      totalCost: 0,
      downPayment: 0,
      loanType: 15,
      interestRate: 15 / 12,
      propertyTaxes: 3,
      insuranceRate: 5,
    };
  },
  components: {
    CheckIcon,
    PreQualificationForm,
  },
  methods: {
    showPreQualificationModal() {
      this.$modal.show('preQualificationModal');
    },
    hidePreQualificationModal() {
      this.$modal.hide('preQualificationModal');
    },
    isMobile() {
      if (typeof window === 'undefined') return;
      return window.innerWidth <= 760;
    },
  },
};
</script>
